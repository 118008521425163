import {green, red, yellow} from "@mui/material/colors";
import _ from "lodash";

function getTriageCategories(){
    return [
        {category: "DECEASED", color: "#000000"},
        {category: "IMMEDIATE", color: red.A700},
        {category: "DELAYED", color: yellow.A700},
        {category: "MINOR", color: green.A700},
    ];
}

export default getTriageCategories;

export function getTriageCategoryColor(category){
    if(!category){
        return "#000000";
    }

    const triageCategories = getTriageCategories();
    const triageCategory = _.find(triageCategories, function(tc){return tc.category === category});
    if(!triageCategory || triageCategory === -1){
        return "#000000";
    }
    return triageCategory.color;
}