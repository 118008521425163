import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import { getAuth, signInAnonymously, browserSessionPersistence, setPersistence } from "firebase/auth";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import FirebaseApp from "./FirebaseApp";
import {Card, CardContent,  CircularProgress, Container, Divider, Grid, Typography} from "@mui/material";
import _ from 'lodash';
import getTriageCategories from "./utils";
import PatientTable from "./PatientTable";

function IncidentPage(){
    let { joinCode } = useParams();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [incident, setIncident] = useState(null);
    const [patients, setPatients] = useState([]);
    const triageCategories = getTriageCategories();
    useEffect(() => {
        let auth = getAuth(FirebaseApp.getApp());
        setPersistence(auth, browserSessionPersistence).then(() => {
            if(auth.currentUser){
                setIsAuthenticated(true);
            }
            else{
                signInAnonymously(auth).then((userCredential) => {
                    setIsAuthenticated(true);
                });
            }
        })

    }, []);

    useEffect(() => {
        if(!isAuthenticated){
            return;
        }
        let db = getFirestore(FirebaseApp.getApp());
        const q = query(collection(db, "incidents"), where("code", "==", _.upperCase(joinCode)));
        onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setIncident(doc.data());
            });
        });

    }, [isAuthenticated, joinCode]);

    useEffect(() => {
        if(incident != null){
            let db = getFirestore(FirebaseApp.getApp());
            const patientQuery = query(collection(db, "patients"), where("incidentId", "==", incident.id));
            onSnapshot(patientQuery, (querySnapshot) => {
                let tempPatients = [];
                querySnapshot.forEach((doc) => {
                    tempPatients.push(doc.data());
                });
                setPatients(tempPatients);
            });
        }
    }, [incident]);

    if(incident === null){
        return (
            <Container>
                <CircularProgress style={{display:'block', margin: 'auto'}}/>
            </Container>
        )
    }

    let needsTransportPatients = _.filter(patients, function(p){return !p.destination});

    return (

            <Grid style={{padding: "10px 10px"}} container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{marginTop: '20px', textAlign: 'center'}} variant={"h3"}>{incident.name}</Typography>
                    <Typography style={{textAlign: 'center'}} variant={"h5"}>Join Code: <b style={{backgroundColor: 'lightgray'}}>{incident.code}</b></Typography>
                </Grid>
                {
                    _.map(triageCategories, function(cat){
                        let numPatients = _.filter(patients, function(p){return p.triageCategory === cat.category}).length;
                        return (
                            <Grid item xs={6} md={3}>
                                <Card style={{backgroundColor: cat.color, color: 'white'}}>
                                    <Typography textAlign={"center"} variant={"h1"}>{numPatients}</Typography>
                                    <Typography textAlign={"center"} variant={"h4"}>{cat.category}</Typography>
                                </Card>
                            </Grid>
                        );
                    })
                }
                {
                    needsTransportPatients.length > 0 && (
                        <Grid item xs={12}>
                            <Typography style={{marginTop: '20px', textAlign: 'center'}} variant={"h3"}>Needs Transport</Typography>
                            <PatientTable patients={needsTransportPatients}/>
                        </Grid>
                    )
                }

                <Grid item xs={12}>
                    <Typography style={{marginTop: '20px', textAlign: 'center'}} variant={"h3"}>Hospitals</Typography>
                </Grid>
                {
                    _.map(incident.destinations, (destination, index) => {
                        let destinationPatients = _.filter(patients, function(p){return p.destination != null && p.destination.id === destination.id});
                        return (
                            <Grid item xs={12} md={6}>
                                <Card elevation={8} style={{height: '500px', overflow:'hidden'}}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography textAlign={"center"} fontWeight={"bold"} variant={"h5"}>{destination.name}</Typography>
                                            </Grid>
                                            {
                                                _.map(triageCategories, function(cat){
                                                    let numPatients = _.filter(destinationPatients, function(p){return p.triageCategory === cat.category}).length;
                                                    return (
                                                        <Grid item xs={6} md={3}>
                                                            <Card style={{backgroundColor: cat.color, color: 'white'}}>
                                                                <Typography textAlign={"center"} variant={"h4"}>{numPatients}</Typography>
                                                                <Typography textAlign={"center"} variant={"h6"}>{cat.category}</Typography>
                                                            </Card>
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                        <Divider style={{paddingTop: '10px'}} />
                                        <PatientTable patients={destinationPatients} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
    )
}

export default IncidentPage;