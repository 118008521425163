import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {getTriageCategoryColor} from "./utils";
import moment from "moment";
import {CheckCircle} from "@mui/icons-material";
import {green} from "@mui/material/colors";



function PatientTable({ patients }) {

    return (
        <TableContainer component={Paper} style={{maxHeight: "340px"}}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                  <TableRow>
                      <TableCell>Tag ID</TableCell>
                      <TableCell align="right">Category</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Triaged By</TableCell>
                      <TableCell align="right">Triaged At</TableCell>
                      <TableCell align="right">Transported By</TableCell>
                      <TableCell align="right">Transported At</TableCell>
                      <TableCell align="right">Arrived At</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {patients.map((patient) => (
                          <TableRow
                              key={patient.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                              <TableCell component="th" scope="row">
                                  {patient.tagId}
                              </TableCell>
                              <TableCell align="right"><span style={{color: getTriageCategoryColor(patient.triageCategory)}}>{patient.triageCategory}</span></TableCell>
                              <TableCell align="right"><span>{patient.status}</span>{patient.status === "ARRIVED" && (<CheckCircle style={{color: green.A700}}/>)}</TableCell>
                              <TableCell align="right">{patient.triagedBy ? patient.triagedBy.unitName : ""}</TableCell>
                              <TableCell align="right">{patient.triagedBy ? moment.unix(patient.triagedAt/1000).fromNow() : ""}</TableCell>
                              <TableCell align="right">{patient.transportedBy ? patient.transportedBy.unitName : ""}</TableCell>
                              <TableCell align="right">{patient.transportedAt ? moment.unix(patient.transportedAt/1000).fromNow() : ""}</TableCell>
                              <TableCell align="right">{patient.arrivedAt ? moment.unix(patient.arrivedAt/1000).fromNow() : ""}</TableCell>
                          </TableRow>
                  ))}
              </TableBody>
          </Table>
        </TableContainer>
    )

}

export default PatientTable;