import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBI7LVX4eEaUWrr1wYTWH4NyOqdhLt53hk",
    authDomain: "sideaction-79273.firebaseapp.com",
    projectId: "sideaction-79273",
    storageBucket: "sideaction-79273.appspot.com",
    messagingSenderId: "975495666548",
    appId: "1:975495666548:web:68f6e060636269c3b26a21",
    measurementId: "G-R6JE4X979B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class FirebaseApp{
    static getApp(){
        return app;
    }
}

export default FirebaseApp;