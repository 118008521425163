import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import IncidentPage from "./IncidentPage";
import HomeInstructions from "./HomeInstructions";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeInstructions/>,
  },
  {
    path: "/incident/:joinCode",
    element: <IncidentPage/>
  }
]);

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
