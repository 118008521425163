import {Container, Fab, Paper, TextField, Typography} from "@mui/material";
import {Login} from "@mui/icons-material";
import React from 'react';

function HomeInstructions(){
    let joinCodeRef = React.createRef();

    return (
        <Container>
            <Paper style={{margin: '20px 20px'}} elevation={3}>
                <Typography style={{padding: '10px 10px'}} variant={"h3"}>Welcome to MCI Triage Track - Wallboard</Typography>
                <Typography style={{padding: '10px 10px'}} variant={"body1"}>To get started, enter an incident Join Code below and click "Go to Incident"</Typography>
                <TextField
                    required
                    inputRef={joinCodeRef}
                    style={{padding: '10px 10px'}}
                    id="outlined-required"
                    label="Join Code"
                />
                <Fab style={{margin: '10px 10px'}} onClick={()=>{
                    window.location.href = "/incident/" + joinCodeRef.current.value;
                }} variant="extended">
                    <Login sx={{ mr: 1 }} />
                    Go to Incident
                </Fab>
            </Paper>
        </Container>
    )
}

export default HomeInstructions;